var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-navigation-drawer', {
    staticClass: "header-drawer",
    attrs: {
      "fixed": ""
    },
    model: {
      value: _vm.drawer,
      callback: function ($$v) {
        _vm.drawer = $$v;
      },
      expression: "drawer"
    }
  }, [_c('v-container', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "header-menu-button",
    attrs: {
      "text": "",
      "plain": "",
      "width": "24",
      "min-width": "24"
    },
    on: {
      "click": _vm.toggleDrawer
    }
  }, [_c('v-img', {
    staticClass: "header-menu-icon",
    attrs: {
      "width": "24",
      "src": "/images/icon/icon-close.svg"
    }
  }), _c('div', [_vm._v("Close")])], 1)], 1)], 1), _c('v-tabs', {
    staticClass: "navigation-tabs navigation-tabs--1",
    model: {
      value: _vm.activeTabData,
      callback: function ($$v) {
        _vm.activeTabData = $$v;
      },
      expression: "activeTabData"
    }
  }, [_vm._l(_vm.items, function (item, index) {
    return [item.children ? [_c('v-tab', {
      key: index
    }, [_c('span', [_vm._v(" " + _vm._s(item.text) + " ")])])] : [_c('v-tab', {
      key: index,
      staticClass: "v-tab--inactive",
      attrs: {
        "to": item.value
      },
      on: {
        "click": _vm.toggleDrawer
      }
    }, [_c('span', [_vm._v(" " + _vm._s(item.text) + " ")])])]];
  })], 2), _c('v-tabs-items', {
    model: {
      value: _vm.activeTabData,
      callback: function ($$v) {
        _vm.activeTabData = $$v;
      },
      expression: "activeTabData"
    }
  }, [_vm._l(_vm.items, function (item, index) {
    return [_c('v-tab-item', {
      key: index
    }, [_c('v-row', {
      staticClass: "row--xxl mt-0"
    }, [_c('v-col', {
      staticClass: "pt-0"
    }, [item.text === '제품' ? [_vm._l(_vm.categories, function (category, index) {
      return [_c('div', {
        key: index,
        staticClass: "navigation-tab-item-wrap"
      }, [_c('div', {
        staticClass: "txt txt--dark font-secondary font-weight-medium mb-12px mb-md-16px"
      }, [_vm._v(" " + _vm._s(category.name) + " ")]), _c('v-tabs', {
        staticClass: "navigation-tabs navigation-tabs--2",
        attrs: {
          "value": _vm.productsTab,
          "vertical": ""
        }
      }, [_vm._l(category.children, function (child, index) {
        return [child.children != 0 ? [_c('v-tab', {
          key: index,
          staticClass: "v-tab--inactive",
          class: {
            'v-tab--productActive': _vm.productsTab == child.code
          },
          on: {
            "!click": function ($event) {
              $event.stopPropagation();
              _vm.productsTab = child.code;
            }
          }
        }, [_c('span', [_vm._v(" " + _vm._s(child.name) + " ")]), _c('v-img', {
          staticClass: "ml-4px",
          attrs: {
            "max-width": "20",
            "src": "/images/icon/icon-drawer-arrow.svg"
          }
        })], 1)] : [_c('v-tab', {
          key: index,
          staticClass: "v-tab--inactive",
          attrs: {
            "to": `/shop?category=${child.code}`
          }
        }, [_c('span', [_vm._v(" " + _vm._s(child.name) + " ")])])]];
      })], 2)], 1)];
    })] : [_vm._l(item.children, function (child, index) {
      return [_c('div', {
        key: index,
        staticClass: "navigation-tab-item-wrap"
      }, [child.children ? [_c('div', {
        staticClass: "txt txt--dark font-secondary font-weight-medium mb-12px mb-md-16px"
      }, [_vm._v(" " + _vm._s(child.text) + " ")]), _c('v-tabs', {
        staticClass: "navigation-tabs navigation-tabs--2",
        attrs: {
          "vertical": ""
        }
      }, [_vm._l(child.children, function (grandChild, index) {
        return [grandChild.children ? [_c('v-tab', {
          key: index
        }, [_c('span', [_vm._v(" " + _vm._s(grandChild.text) + " ")]), _c('v-img', {
          staticClass: "ml-4px",
          attrs: {
            "max-width": "20",
            "src": "/images/icon/icon-drawer-arrow.svg"
          }
        })], 1)] : [_c('v-tab', {
          key: index,
          staticClass: "v-tab--inactive",
          attrs: {
            "to": grandChild.value
          }
        }, [_c('span', [_vm._v(" " + _vm._s(grandChild.text) + " ")])])]];
      })], 2)] : [_c('router-link', {
        staticClass: "txt txt--dark font-secondary font-weight-medium mb-12px mb-md-16px",
        attrs: {
          "to": child.value
        }
      }, [_vm._v(" " + _vm._s(child.text) + " ")])]], 2)];
    })]], 2), item.text === '제품' ? [_c('v-col', {
      staticClass: "products-tabs-items pt-0"
    }, [_vm._l(_vm.categories, function (category, index) {
      return [_c('v-tabs-items', {
        key: index,
        model: {
          value: _vm.productsTab,
          callback: function ($$v) {
            _vm.productsTab = $$v;
          },
          expression: "productsTab"
        }
      }, [_vm._l(category.children, function (child, index) {
        return [_c('v-tab-item', {
          key: index,
          attrs: {
            "value": child.code
          }
        }, [_c('div', {
          staticClass: "navigation-tab-item-wrap"
        }, [_c('v-tabs', {
          staticClass: "navigation-tabs navigation-tabs--3",
          attrs: {
            "vertical": ""
          }
        }, [child.code === 'bathroom_fittings' ? [_c('v-tab', {
          staticClass: "v-tab--inactive",
          attrs: {
            "to": "/shop/bathroom-fittings/overview"
          },
          on: {
            "click": _vm.toggleDrawer
          }
        }, [_vm._v("욕실 수전 개요")])] : _vm._e(), _vm._l(child.children, function (grandChild, index) {
          return [_c('v-tab', {
            key: index,
            staticClass: "v-tab--inactive",
            attrs: {
              "to": `/shop?category=${grandChild.code}`
            },
            on: {
              "click": _vm.toggleDrawer
            }
          }, [_c('span', [_vm._v(" " + _vm._s(grandChild.name) + " ")])])];
        })], 2)], 1)])];
      })], 2)];
    })], 2)] : _vm._e()], 2)], 1)];
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }