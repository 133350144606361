var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "main-visual"
  }, [_vm._l(_vm.items, function (item) {
    return [_c('v-card', {
      key: item,
      staticClass: "main-visual__inner",
      attrs: {
        "img": item.image,
        "tile": "",
        "flat": ""
      }
    }, [_c('v-container', [_c('v-sheet', {
      staticClass: "main-visual__text",
      attrs: {
        "color": "white"
      }
    }, [_c('h2', {
      staticClass: "tit font-tertiary font-weight-regular mt-auto mb-12px mb-md-16px"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('p', {
      staticClass: "txt txt--sm"
    }, [_vm._v(" " + _vm._s(item.text) + " ")]), _c('v-btn', {
      staticClass: "v-btn--no-hover mt-8px pa-0",
      attrs: {
        "text": "",
        "large": "",
        "ripple": false,
        "to": item.link
      }
    }, [_c('span', {
      staticClass: "txt txt--xs txt--dark font-weight-medium"
    }, [_vm._v(" " + _vm._s(item.button) + " ")]), _c('v-img', {
      staticClass: "ml-8px",
      attrs: {
        "src": "/images/icon/icon-arrow-right.svg"
      }
    })], 1)], 1)], 1)], 1)];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }