var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "section-title"
  }, [_c('div', {
    staticClass: "txt txt--xs font-secondary spaced-text mb-12px mb-md-16px"
  }, [_vm._v("쇼핑")]), _c('h2', {
    staticClass: "tit font-tertiary font-weight-regular mb-14px mb-md-16px"
  }, [_vm._v("세면기")]), _c('p', {
    staticClass: "txt txt--sm font-secondary"
  }, [_vm._v("상품 둘러보기")]), _c('div', {
    staticClass: "mt-18px mt-md-24px"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "swiper-button swiper-button--prev",
    attrs: {
      "x-large": "",
      "icon": "",
      "outlined": ""
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-swiper-left.svg"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "swiper-button swiper-button--next",
    attrs: {
      "x-large": "",
      "icon": "",
      "outlined": ""
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-swiper-right.svg"
    }
  })], 1)], 1)], 1)], 1)])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('swiper', _vm._b({
    staticClass: "swiper"
  }, 'swiper', {
    options: _vm.options
  }, false), [_vm._l(6, function (item) {
    return [_c('swiper-slide', {
      key: item
    }, [_c('shop-product-card')], 1)];
  })], 2), _c('v-sheet', {
    attrs: {
      "color": "grey lighten-5 mt-18px mt-md-24px pa-14px px-md-24px py-md-16px"
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("세면기에 대해 더 알아보세요")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "rounded-xs sales-button",
    attrs: {
      "large": "",
      "color": "white"
    }
  }, [_vm._v(" Lorem Ipsum dolor sit amet "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "src": "/images/icon/icon-favorite-plus.svg"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }