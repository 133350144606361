<template>
    <main-section>
        <v-container>
            <div class="tit-wrap text-center">
                <h2 class="tit font-tertiary">제품 하이라이트</h2>
            </div>
            <v-tabs centered class="mb-20px mb-md-40px" v-model="highlightsTab">
                <template v-for="(item, index) in items">
                    <v-tab :key="index">
                        <v-btn large class="rounded-xs">
                            {{ item.tab }}
                        </v-btn>
                    </v-tab>
                </template>
            </v-tabs>
            <v-tabs-items v-model="highlightsTab">
                <template v-for="(item, index) in items">
                    <v-tab-item :key="index">
                        <v-row>
                            <v-col cols="6" sm="4" lg="3">
                                <shop-product-card />
                            </v-col>
                        </v-row>
                        <div class="product-lists__pagination">
                            <pagination-component :value="page" :count="pageCount" @input="(page) => $router.push({ query: { ...$route.query, page } })"></pagination-component>
                        </div>
                    </v-tab-item>
                </template>
            </v-tabs-items>
        </v-container>
    </main-section>
</template>

<script>
import MainSection from "@/components/client/templates/main-section.vue";
import ShopProductCard from "@/components/client/shop/shop-product-card.vue";
import PaginationComponent from "../pagination-component.vue";

export default {
    components: {
        MainSection,
        ShopProductCard,
        PaginationComponent,
    },
    data: () => ({
        highlightsTab: null,
        items: [
            {
                tab: "욕실",
            },
            {
                tab: "주방",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
.v-tab {
    &::before {
        display: none;
    }
    .v-btn {
        border: 1px solid var(--v-grey-lighten3);
        background-color: transparent !important;
        color: var(--v-grey-darken3) !important;
        text-transform: initial;
    }
    &--active {
        .v-btn {
            border-color: var(--v-primary-base);
            background-color: var(--v-primary-base) !important;
            color: #fff !important;
        }
    }
}

@media (min-width: 768px) {
    .v-tab {
        padding: 0 12px;
    }
}
</style>